@font-face {
  font-family: 'Cultura';
  src:  url('fonts/Cultura.eot?dmaxfw');
  src:  url('fonts/Cultura.eot?dmaxfw#iefix') format('embedded-opentype'),
    url('fonts/Cultura.ttf?dmaxfw') format('truetype'),
    url('fonts/Cultura.woff?dmaxfw') format('woff'),
    url('fonts/Cultura.svg?dmaxfw#Cultura') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.cul {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Cultura' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cul-exclamation .path1:before {
  content: "\e900";
  color: rgb(106, 150, 59);
}
.cul-exclamation .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-exclamation .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-question .path1:before {
  content: "\e903";
  color: rgb(106, 150, 59);
}
.cul-question .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-question .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sms .path1:before {
  content: "\e906";
  color: rgb(106, 150, 59);
}
.cul-sms .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sms .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sms .path4:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sms .path5:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-gears .path1:before {
  content: "\e90b";
  color: rgb(113, 143, 64);
}
.cul-gears .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-gears .path3:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-percent .path1:before {
  content: "\e90e";
  color: rgb(106, 150, 59);
}
.cul-percent .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-percent .path3:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-percent .path4:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-pen .path1:before {
  content: "\e912";
  color: rgb(106, 150, 59);
}
.cul-pen .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-info .path1:before {
  content: "\e914";
  color: rgb(106, 150, 59);
}
.cul-info .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-info .path3:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-check .path1:before {
  content: "\e917";
  color: rgb(106, 150, 59);
}
.cul-check .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-customer-empty .path1:before {
  content: "\e919";
  color: rgb(106, 150, 59);
}
.cul-customer-empty .path2:before {
  content: "\e91a";
  margin-left: -0.9775390625em;
  color: rgb(255, 255, 255);
}
.cul-customer-empty .path3:before {
  content: "\e91b";
  margin-left: -0.9775390625em;
  color: rgb(255, 255, 255);
}
.cul-customer .path1:before {
  content: "\e91c";
  color: rgb(255, 255, 255);
}
.cul-customer .path2:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(113, 143, 64);
}
.cul-customer .path3:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-customer .path4:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-customer-bars .path1:before {
  content: "\e920";
  color: rgb(106, 150, 59);
}
.cul-customer-bars .path2:before {
  content: "\e921";
  margin-left: -0.9775390625em;
  color: rgb(255, 255, 255);
}
.cul-customer-bars .path3:before {
  content: "\e922";
  margin-left: -0.9775390625em;
  color: rgb(255, 255, 255);
}
.cul-customer-bars .path4:before {
  content: "\e923";
  margin-left: -0.9775390625em;
  color: rgb(255, 255, 255);
}
.cul-customer-chart .path1:before {
  content: "\e924";
  color: rgb(113, 143, 64);
}
.cul-customer-chart .path2:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-customer-chart .path3:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-customer-chart .path4:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-customer-chart .path5:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid-mobile .path1:before {
  content: "\e929";
  color: rgb(106, 150, 59);
}
.cul-bankid-mobile .path2:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid-mobile .path3:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid-mobile .path4:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid-mobile .path5:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid-mobile .path6:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid-mobile .path7:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid-mobile .path8:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid-mobile .path9:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid-mobile .path10:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid .path1:before {
  content: "\e933";
  color: rgb(106, 150, 59);
}
.cul-bankid .path2:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid .path3:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid .path4:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid .path5:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid .path6:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid .path7:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid .path8:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-bankid .path9:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sheet-pen .path1:before {
  content: "\e93c";
  color: rgb(106, 150, 59);
}
.cul-sheet-pen .path2:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sheet-pen .path3:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sheet-pen .path4:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sheet-pen .path5:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sheet-pen .path6:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sheet-pen .path7:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sheet-pen .path8:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(106, 150, 59);
}
.cul-sheet .path1:before {
  content: "\e944";
  color: rgb(106, 150, 59);
}
.cul-sheet .path2:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sheet .path3:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sheet .path4:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sheet .path5:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sheet .path6:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.cul-sheet .path7:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
